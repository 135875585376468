import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { formatDate } from './formatDate';
import { DividerBlock } from './dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const CurrentExhibitions = ({ accentColor }) => {
  const data = useStaticQuery(graphql`
    query CurrentExhibitionsQuery {
      currentExhibitions: allDatoCmsSingleExhibition(
        sort: {
          fields: startDate,
          order: ASC
        },
        filter: {
          exhibitionStatus: {
            eq: "CURRENT"
          }
          meta: { isValid: { eq: true }, status: { ne: "draft" } }
        }
      ) {
        edges {
          node {
            featuredImage {
							url(imgixParams: { h: "550", ar: "5:3", fit: "crop", crop: "focalpoint" })
							alt
            }
            id
            formattedTitle
            slug
            startDate
            endDate
            locations {
              location {
                title
              }
            }
          }
        }
      }
    }
  `);
  const current = data.currentExhibitions.edges;

  return (
    <>
      {current.length > 0 && (
        <div className="exhibitions-current container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Current</span>
          </div>
          <div className="col-sm-12 col-xs-6 row col spacing-0">
            {current.map(({ node: e }) => (
              <div className="col-sm-7 col-xs-5 col exhibitions-container" key={e.id}>
                <Link to={`/exhibition/${e.slug}`} className="exhibition-tile">
                  {e.featuredImage && (
										<ImageFadeIn
										src={e.featuredImage.url}
										alt={e.featuredImage.alt}
										className="exhibition-featured-image"
									/>
                  )}
                  <div className="exhibition-tile-text-wrapper">
                    <h2
                      className="sans--large exhibition-title"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: e.formattedTitle,
                      }}
                    />
                    {e.locations[0] && (
                      <span className="exhibition-location">{e.locations[0].location.title}</span>
                    )}
                    <span className="exhibition-dates">
                      {`${formatDate(e.startDate).month} ${formatDate(e.startDate).date}, ${formatDate(e.startDate).year}`}
                      &nbsp;-&nbsp;
                      {`${formatDate(e.endDate).month} ${formatDate(e.endDate).date}, ${formatDate(e.endDate).year}`}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-8 col">
            <DividerBlock accentColor={accentColor.hex} className="current-exhibition--divider-block" />
          </div>
        </div>
      )}
    </>
  );
};

CurrentExhibitions.propTypes = {
  accentColor: PropTypes.shape().isRequired,
};

export default CurrentExhibitions;
