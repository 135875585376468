import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { formatDate } from './formatDate';
import { DividerBlock } from './dividerBlock';
import ImageFadeIn from "react-image-fade-in"

const UpcomingExhibitions = ({ accentColor }) => {
  const data = useStaticQuery(graphql`
    query UpcomingExhibitionsQuery {
      upcomingExhibitions: allDatoCmsSingleExhibition(
        sort: {
          fields: startDate,
          order: ASC
        },
        filter: {
          exhibitionStatus: {
            eq: "UPCOMING"
          }
          meta: { isValid: { eq: true }, status: { ne: "draft" } }
        }
      ) {
        edges {
          node {
            featuredImage {
							url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
							alt
            }
            id
            formattedTitle
            slug
            startDate
            endDate
            locations {
              location {
                title
              }
            }
          }
        }
      }
    }
  `);
  const upcoming = data.upcomingExhibitions.edges;

  return (
    <>
      {upcoming.length > 0 && (
        <div className="exhibitions-upcoming container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Upcoming</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {upcoming.map(({ node: e }) => (
              <div className="tile exhibition-tile-wrapper col" key={e.id}>
                <Link to={`/exhibition/${e.slug}`} className="exhibition-tile">
                  {e.featuredImage && (
										<ImageFadeIn
											src={e.featuredImage.url}
											alt={e.featuredImage.alt}
											className="exhibition-featured-image"
										/>
									)}
                  <div className="exhibition-tile-text-wrapper tile-text-wrap">
                    <h3
                      className="sans--regular exhibition-title"
                    // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: e.formattedTitle,
                      }}
                    />
                    {e.locations[0] && (
                      <p className="exhibition-location">{e.locations[0].location.title}</p>
                    )}
                    <p className="exhibition-dates">
                      {`${formatDate(e.startDate).month} ${formatDate(e.startDate).date}, ${formatDate(e.startDate).year}`}
                    &nbsp;-&nbsp;
                      {`${formatDate(e.endDate).month} ${formatDate(e.endDate).date}, ${formatDate(e.endDate).year}`}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col">
            <DividerBlock accentColor={accentColor.hex} />
          </div>
        </div>
      )}
    </>
  );
};

UpcomingExhibitions.propTypes = {
  accentColor: PropTypes.shape().isRequired,
};

export default UpcomingExhibitions;
