import React, { useState, useRef, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { formatDate } from './formatDate';
import { DividerBlock } from './dividerBlock';
import FilterArrow from '../images/filter-arrow.inline.svg';
import {
  defaultFilterValue,
  filterItemByLocation,
  filterItemByYear,
  getUniqueYearList,
  getUniqueLocationNameList,
  useFilters,
  loadMoreVisibility,
  handleLoadMore,
} from '../helpers/filtering';
import ImageFadeIn from "react-image-fade-in"


export const PastExhibitions = ({ accentColor }) => {
  const data = useStaticQuery(graphql`
    query PastExhibitionsQuery {
      pastExhibitions: allDatoCmsSingleExhibition(
        sort: {
          fields: startDate,
          order: DESC
        },
        filter: {
          exhibitionStatus: {
            in: ["", "PAST"]
          }
          meta: { isValid: { eq: true }, status: { ne: "draft" } }
        }
      ) {
        edges {
          node {
            featuredImage {
							url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
							alt
            }
            id
            formattedTitle
            slug
            startDate
            endDate
            model {
              name
            }
            locations {
              location {
                title
              }
            }
          }
        }
      }
    }
  `);

  const items = data.pastExhibitions.edges;
  const increment = 16;
  const years = getUniqueYearList(items);
  const yearRef = useRef(null);
  const galleries = getUniqueLocationNameList(items);
  const galleryRef = useRef(null);

  const [activeItems, setActiveItems] = useState(items);
  const [visibleLimit, setVisibleLimit] = useState(increment);

  const [filters, setFilters] = useState({ gallery: defaultFilterValue, year: defaultFilterValue });

  const handleFilterItems = (exhibitions) => {
    const filteredExhibitions = exhibitions
      .filter((exhibition) => filterItemByLocation(filters.gallery, exhibition))
      .filter((exhibition) => filterItemByYear(filters.year, exhibition));
    setVisibleLimit(increment);
    setActiveItems(filteredExhibitions);
  };

  const handleFilters = () => {
    setFilters({
      year: yearRef.current.value || defaultFilterValue,
      gallery: galleryRef.current.value || defaultFilterValue,
    });
  };

  useFilters(filters, handleFilterItems, items);

  useEffect(() => {
    handleFilters();
  }, []);

  return (
    <>
      {items.length > 0 && (
        <div className="exhibitions-past container row">
          <div className="col-sm-14 col-xs-6 col filter-row">
            <div className="filter-name">Past exhibitions</div>
            <div className="filters">
              {years.length > 0 && (
              <label
                className="filter"
                htmlFor="past-year-filter"
              >
                <span className="filter-text">
                  Year:
                </span>
                <div className="custom-filter-select">
                  <span>{filters.year}</span>
                  <select
                    ref={yearRef}
                    name="year"
                    id="past-year-filter"
                    onChange={() => { handleFilters(); }}
                  >
                    <option value={defaultFilterValue}>{defaultFilterValue}</option>
                    {years.map((yearValue) => (
                      <option key={yearValue} value={yearValue}>{yearValue}</option>
                    ))}
                  </select>
                  <FilterArrow />
                </div>
              </label>
              )}
              {galleries.length > 0 && (
                <label
                  className="filter"
                  htmlFor="past-gallery-filter"
                >
                  <div className="filter-text">
                    Gallery:
                  </div>
                  <div className="custom-filter-select">
                    <span className="filter-selected">{filters.gallery}</span>
                    <select
                      ref={galleryRef}
                      id="past-gallery-filter"
                      onChange={() => { handleFilters(); }}
                    >
                      <option value={defaultFilterValue}>{defaultFilterValue}</option>
                      {galleries.map((val) => (
                        <option key={val} value={val}>{val}</option>
                      ))}
                    </select>
                    <FilterArrow />
                  </div>
                </label>
              )}
            </div>
          </div>
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text filter">Past exhibitions</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {activeItems.map(({ node: item }, i) => (
              <div className="tile exhibition-tile-wrapper col" key={item.id} style={{ display: `${loadMoreVisibility(i, visibleLimit)}` }}>
                <Link to={`/exhibition/${item.slug}`} className="exhibition-tile">
                  {item.featuredImage && (
										<ImageFadeIn
											src={item.featuredImage.url}
											alt={item.featuredImage.alt}
											className="exhibition-featured-image"
										/>
                  )}
                  <div className="exhibition-tile-text-wrapper tile-text-wrapper">
                    <h3
                      className="sans--regular exhibition-title"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.formattedTitle,
                      }}
                    />
                    {item.locations[0] && (
                      <p className="exhibition-location">{item.locations[0].location.title}</p>
                    )}
                    <p className="exhibition-dates">
                      {`${formatDate(item.startDate).month} ${formatDate(item.startDate).date}, ${formatDate(item.startDate).year}`}
                        &nbsp;-&nbsp;
                      {`${formatDate(item.endDate).month} ${formatDate(item.endDate).date}, ${formatDate(item.endDate).year}`}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="container col row">
            <div className="offset-sm-2 col-sm-12 col-xs-6 col row spacing-0">
              {activeItems.length > visibleLimit && <button onClick={() => { handleLoadMore(setVisibleLimit, visibleLimit, increment); }} type="button" aria-label="Load more" className="btn show-more">Load more</button>}
            </div>
          </div>
          <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-8 col">
            <DividerBlock accentColor={accentColor.hex} />
          </div>
        </div>
      )}
    </>
  );
};

PastExhibitions.propTypes = {
  accentColor: PropTypes.shape().isRequired,
};
