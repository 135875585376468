import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import CurrentExhibitions from "../components/currentExhibitions"
import UpcomingExhibitions from "../components/upcomingExhibitions"
import { PastExhibitions } from "../components/pastExhibitions"

const ExhibitionsPage = ({ data }) => (
  <Layout
    bgcolor={data.exhibition.backgroundColor}
    wrapperClass="exhibition"
    accentColor={data.exhibition.accentColor}
  >
    <HelmetDatoCms title="Exhibitions" />
    <div className="exhibitions-intro container row">
      <div className="col-sm-12 offset-sm-2 col-xs-6 col">
        <h1 className="gothic--large large-title">{data.exhibition.title}</h1>
      </div>
    </div>
    <CurrentExhibitions accentColor={data.exhibition.accentColor} />
    <UpcomingExhibitions accentColor={data.exhibition.accentColor} />
    <PastExhibitions accentColor={data.exhibition.accentColor} />
  </Layout>
)

ExhibitionsPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
}

export default ExhibitionsPage

export const query = graphql`
  query ExhibitionAllQuery {
    exhibition: datoCmsExhibition {
      backgroundColor
      accentColor {
        hex
      }
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
